import eruda from "eruda";

eruda.init();

function App() {
  const tgInitString = sessionStorage.getItem("__telegram__initParams");
  if (tgInitString === "{}") return "Hello, storage is empty!";
  const tgInit = JSON.parse(tgInitString);
  const tgWebAppData = tgInit.tgWebAppData;

  console.log(tgWebAppData);

  return (
    <>
      <div className="home">{tgWebAppData}</div>
    </>
  );
}

export default App;
